import { on } from 'delegated-events'
import { onDomReady } from 'utils/onDomReady'

const MULTISELECT_SELECTOR = '.multiselect'
const MULTISELECT_CLEAR_SELECTOR = '.multiselect__clear'
const MULTISELECT_TOGGLE_SELECTOR = '.multiselect__toggle'
const MULTISELECT_CHECKBOX_SELECTOR = '.multiselect__checkbox'

const MULTISELECT_ACTIVE_CLASSNAME = 'multiselect--active'

const MULTISELECT_SEARCH_SELECTOR = '.multiselect__search'
const MULTISELECT_ITEM_SELECTOR = '.multiselect__item'

function handleSearch(event) {
  const searchTerm = event.target.value.toLowerCase().trim()
  const multiselect = event.target.closest(MULTISELECT_SELECTOR)
  const items = multiselect.querySelectorAll(MULTISELECT_ITEM_SELECTOR)

  items.forEach(item => {
    const text = item
      .querySelector('.multiselect__text')
      .textContent.toLowerCase()
    if (text.includes(searchTerm)) {
      item.style.display = ''
    } else {
      item.style.display = 'none'
    }
  })
}

function clearSearchInput(dropdown) {
  const searchInput = dropdown.querySelector(MULTISELECT_SEARCH_SELECTOR)
  if (searchInput) {
    searchInput.value = ''

    const items = dropdown.querySelectorAll(MULTISELECT_ITEM_SELECTOR)
    items.forEach(item => {
      item.style.display = ''
    })
  }
}

function focusSearchInput(multiselect) {
  if (window.matchMedia('(min-width: 1024px)').matches) {
    const searchInput = multiselect.querySelector(MULTISELECT_SEARCH_SELECTOR)
    if (searchInput) {
      searchInput.focus()
    }
  }
}

function toggleDropdown(event) {
  const dropdownToggle = event.target
  const dropdownMenu = dropdownToggle.closest(MULTISELECT_SELECTOR)
  dropdownMenu.classList.toggle(MULTISELECT_ACTIVE_CLASSNAME)

  if (dropdownMenu.classList.contains(MULTISELECT_ACTIVE_CLASSNAME)) {
    focusSearchInput(dropdownMenu)
  } else {
    clearSearchInput(dropdownMenu)
  }
}

function outsideDropdownClick(event) {
  const target = event.target
  const dropdowns = document.querySelectorAll(MULTISELECT_SELECTOR)

  dropdowns.forEach(dropdown => {
    if (!dropdown.contains(target)) {
      clearSearchInput(dropdown)
      dropdown.classList.remove(MULTISELECT_ACTIVE_CLASSNAME)
    }
  })
}

function updateSelectedItems(multiselect, clearButton) {
  const selectedItemsContainer = multiselect.querySelector(
    MULTISELECT_TOGGLE_SELECTOR
  )
  const originalText = selectedItemsContainer.getAttribute('data-text')
  const checkboxes = multiselect.querySelectorAll(
    MULTISELECT_CHECKBOX_SELECTOR + ':checked'
  )

  if (checkboxes.length === 0) {
    selectedItemsContainer.textContent = `All ${originalText}`
  } else if (checkboxes.length === 1) {
    selectedItemsContainer.textContent = checkboxes[0].value
  } else {
    selectedItemsContainer.textContent = `${checkboxes.length} ${originalText} selected`
  }

  clearButton.textContent = `Clear All ${
    checkboxes.length ? '(' + checkboxes.length + ')' : ''
  }`
}

export function updateSelectedItemsOnPageLoad() {
  const multiselects = document.querySelectorAll(MULTISELECT_SELECTOR)
  multiselects.forEach(multiselect => {
    const clearButton = multiselect.querySelector(MULTISELECT_CLEAR_SELECTOR)
    updateSelectedItems(multiselect, clearButton)
  })
}

function clearAllItems(event, excludeCheckbox = null) {
  event.preventDefault()
  const multiselect = event.target.closest(MULTISELECT_SELECTOR)
  const checkboxes = multiselect.querySelectorAll(
    MULTISELECT_CHECKBOX_SELECTOR + ':checked'
  )
  const clearButton = multiselect.querySelector(MULTISELECT_CLEAR_SELECTOR)

  checkboxes.forEach(checkbox => {
    if (checkbox !== excludeCheckbox) {
      checkbox.checked = false
    }
  })

  updateSelectedItems(multiselect, clearButton)
}

on('click', MULTISELECT_TOGGLE_SELECTOR, toggleDropdown)
document.addEventListener('click', outsideDropdownClick)

on('change', MULTISELECT_CHECKBOX_SELECTOR, event => {
  event.stopPropagation()
  const multiselectMenu = event.target.closest(MULTISELECT_SELECTOR)
  const clearButton = multiselectMenu.querySelector(MULTISELECT_CLEAR_SELECTOR)
  const isSingleSelect =
    multiselectMenu.getAttribute('data-single-select') === 'true'

  if (isSingleSelect) {
    clearAllItems(event, event.target)
  }

  updateSelectedItems(multiselectMenu, clearButton)
  focusSearchInput(multiselectMenu)
})

on('click', MULTISELECT_CLEAR_SELECTOR, clearAllItems)

onDomReady(() => {
  updateSelectedItemsOnPageLoad()
})

on('input', MULTISELECT_SEARCH_SELECTOR, handleSearch)
